import React, { AllHTMLAttributes, createElement } from 'react';
import Spinner from '../Spinner';
import NextChevron from '../../../public/static/icons/navigation-chevron-right-white-thick.svg';
import ExternalLink from '../../../public/static/icons/external-link-2.svg';
import { ChildrenProps } from '../../../types';

import s from './index.module.scss';

type Props = {
  /**
   * Uses `<a>` or `<button>` tags in html.
   */
  tag?: ButtonTag;
  buttonType?: ButtonTagType;
  type?: ButtonType;
  size?: ButtonSize;
  className?: string;
  href?: string;
  children: ChildrenProps;
  id?: string;
  isDisabled?: boolean;
  isTransparent?: boolean;
  isOutlined?: boolean;
  /**
   * Displays loading icon on a button.
   */
  isLoading?: boolean;
  /**
   * Displays as a block.
   */
  isBlock?: boolean;
  /**
   * Displays uppercase label for a button.
   */
  isUppercase?: boolean;
  /**
   * Displays as a block on mobile devices and as inline element on other devices.
   */
  isMobileBlock?: boolean;
  /**
   * Adds external icon on the button.
   */
  isExternal?: boolean;
  /**
   * Adds arrow on the button.
   */
  withArrow?: boolean;
  withBackArrow?: boolean;
  onClick?: (e: React.KeyboardEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  tabIndex?: number;
};

type ButtonTag = 'button' | 'a';
type ButtonTagType = 'button' | 'submit' | 'reset';
export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'emergency'
  | 'white-pink'
  | 'white-green'
  | 'quick-link'
  | 'toggled'
  | 'untoggled'
  | 'cross-selling-blue'
  | 'cross-selling-pink'
  | 'cross-selling-green'
  | 'activism'
  | 'activism-yellow'
  | 'activism-black'
  | 'tangerine'
  | 'cobalt'
  | 'xmas'
  | 'dark'
  | 'neutral'
  | 'light';

export type ButtonSize = 'small' | 'large' | 'extra-large';

/**
 * Buttons allow users to trigger an action or event with a single click.
 * For example, you can use a button for allowing the functionality of submitting a form,
 * opening a dialog, canceling an action or redirecting users to another resource.
 */
const Button = ({
  tag = 'button',
  buttonType = 'submit',
  type = 'primary',
  size = 'large',
  className = '',
  isUppercase = false,
  isOutlined = false,
  isDisabled = false,
  isTransparent = false,
  isBlock = false,
  isLoading = false,
  isMobileBlock = false,
  isExternal = false,
  children = '',
  withArrow = false,
  withBackArrow = false,
  onClick = () => {},
  onKeyDown,
  ...props
}: Props) => {
  const btnProps: AllHTMLAttributes<HTMLAnchorElement | HTMLButtonElement> & {
    'data-component'?: string;
  } = {};

  const classes = [s['button'], className];
  classes.push(s[`button-${type}`]);
  classes.push(s[`button-${size}`]);

  if (isOutlined) {
    classes.push(s['button-outline']);
  }

  if (isBlock) {
    classes.push(s['button-block']);
  }

  if (isMobileBlock) {
    classes.push(s['button-mobile-block']);
  }

  if (isExternal) {
    classes.push(s['button-external']);
  }

  if (withArrow) {
    classes.push(s['button-with-arrow']);
  }

  if (withBackArrow) {
    classes.push(s['button-with-back-arrow']);
  }

  if (isLoading) {
    classes.push(s['button-loading']);
  }

  if (isUppercase) {
    classes.push(s['button-caps']);
  }

  if (isTransparent) {
    classes.push(s['button-transparent']);
  }

  btnProps.className = classes.join(' ');

  if (tag === 'button') {
    btnProps.type = 'submit';
    if (buttonType) {
      btnProps.type = buttonType;
    }
  }

  // Add a data-component attribute based upon the button type.
  btnProps['data-component'] = `button-${type}`;

  // For better accessibility disabled buttons remain focusable. For that reason we have to
  // prevent default behaviour for aria-disabled buttons.
  function handleClick(e: React.KeyboardEvent) {
    if (isDisabled) {
      e.preventDefault();
    } else {
      onClick(e);
    }
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    if (onKeyDown) {
      onKeyDown(e);
      return;
    }

    const enter = 13;
    if (e.keyCode === enter) {
      onClick(e);
    }
  }
  return createElement(
    tag,
    {
      // tabIndex could be overwritten by props.
      tabIndex: 0,
      ...btnProps,
      ...props,
      'aria-disabled': tag !== 'a' ? isDisabled : null,
      onClick: handleClick,
      onKeyDown: handleKeyDown,
    },
    <>
      {isLoading && <Spinner />}
      {withBackArrow && <NextChevron aria-hidden="true" />}
      <span>{children}</span>
      {withArrow && <NextChevron aria-hidden="true" />}
      {isExternal && <ExternalLink aria-label="Opens a new window" />}
    </>,
  );
};

export default Button;
