import { createElement, MutableRefObject, useLayoutEffect, useRef } from 'react';

import s from './index.module.scss';

type Props = {
  className?: string;
  text: string;
  tag?: string;
  id?: string;
  ref?: MutableRefObject<HTMLElement | undefined>;
};

const createHtmlElement = ({ className, text = '', tag = 'div', id = '', ...props }: Props) =>
  createElement(tag, {
    id,
    className: `${s['formatted-text']} ${className}`,
    'data-component': 'formatted-text',
    ...props,
    dangerouslySetInnerHTML: { __html: text },
  });

const FormattedText = (props: Props) => {
  return createHtmlElement(props);
};

export const FormattedHtmlWithScriptTag = (props: Props) => {
  const ref = useRef<HTMLElement>();

  useLayoutEffect(() => {
    if (ref.current) {
      const range = document.createRange();
      range.selectNode(ref.current);
      const documentFragment = range.createContextualFragment(props.text);

      // Inject the markup, triggering a re-run!
      ref.current.innerHTML = '';
      ref.current.append(documentFragment);
    }
  }, []);

  return createHtmlElement({ ...props, ref });
};

export default FormattedText;
